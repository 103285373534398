import gql from 'graphql-tag';

export const SAVE_BILLING_APPOINMENT = gql`
	mutation saveBillingAppointment(
		$appointmentId: ID!
		$data: BillingAppointment!
    ) {
		saveBillingAppointment(
			appointmentId: $appointmentId
			data: $data
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;